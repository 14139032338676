import moment from "moment";
import DxLoadOptions from "../utils/DxLoadOptions";
window.DxLoadOptions = DxLoadOptions;
const getDefaultState = () => {
    return {
        dialogChiTietChuyenDi: false,
        dialogDsChuyenDi: false,
        dialogDsChonDiemDen: false,
        dialogLocDiemDen: true,
        laChuyenDiSapXuatBen: false,
        timKiemTheoViTri: false,
        tempLayCacChuyenDiDauTien: 0, //vẽ bản đồ theo biến này
        data: {
            dsChuyenDi: null,
            dsTatCaChuyenDi: [],
            dsDiemDen: [],
            dsNamChuyenDiDauTien: [],
            dsTinh: [],
            dsHuyen: [],
            dsXa: [],
        },
        model: {
            timKiem: "",
            sapXepDsChuyenDi: [],
            locNgayDsChuyenDi: moment(new Date()).format("yyyy-MM-DD"),
            diemDenDangChon: null,
            tinhDangChon: null,
            huyenDangChon: null,
            xaDangChon: null,
        },
        old: {
            //Lưu lại để không load lại dữ liệu đã tìm kiếm trước đó
            diemDung: "", //placemarkId
        },
        guidNhaXe: "",
    };
};
export default {
    namespaced: true,

    state: getDefaultState,
    getters: {
        getDialogChiTietChuyenDi: (state) => state.dialogChiTietChuyenDi,
        getDialogDsChuyenDi: (state) => state.dialogDsChuyenDi,
        getDialogDsChonDiemDen: (state) => state.dialogDsChonDiemDen,
        getDialogLocDiemDen: (state) => state.dialogLocDiemDen,
        getLaChuyenDiSapXuatBen: (state) => state.laChuyenDiSapXuatBen,
        getTimKiemTheoViTri: (state) => state.timKiemTheoViTri,
        getTempLayCacChuyenDiDauTien: (state) =>
            state.tempLayCacChuyenDiDauTien,
        //model
        getSapXepDsChuyenDi: (state) => state.model.sapXepDsChuyenDi,
        getTimKiem: (state) => state.model.timKiem,
        getDiemDenDangChon: (state) => state.model.diemDenDangChon,
        getTinhDangChon: (state) => state.model.tinhDangChon,
        getHuyenDangChon: (state) => state.model.huyenDangChon,
        getXaDangChon: (state) => state.model.xaDangChon,
        //data
        getDataDsChuyenDi: (state) => state.data.dsChuyenDi,
        getDataDsTatCaChuyenDi: (state) => state.data.dsTatCaChuyenDi,
        getDataDsDiemDen: (state) => state.data.dsDiemDen,
        getDsNamChuyenDiDauTien: (state) => state.data.dsNamChuyenDiDauTien,
        getDsTinh: (state) => state.data.dsTinh,
        getDsHuyen: (state) => state.data.dsHuyen,
        getDsXa: (state) => state.data.dsXa,
        //old
        getOldDiemDung: (state) => state.old.diemDung,
    },
    mutations: {
        set(state, { key, value }) {
            state[key] = value;
        },
        setModel(state, { key, value }) {
            state["model"][key] = value;
        },
        setData(state, { key, value }) {
            state["data"][key] = value;
        },
        setOld(state, { key, value }) {
            state["old"][key] = value;
        },
        setDialogChiTietChuyenDi(state, value) {
            state.dialogDsChuyenDi = false;
            state.dialogChiTietChuyenDi = value;
            if (!value) {
                return (state.dialogLocDiemDen = true);
            }
            //Không đóng popup chọn tỉnh khi bản đồ bị lỗi
            let m = this.getters["banDo/map"];
            if (!m) {
                return;
            }
            state.dialogLocDiemDen = false;
        },
        setDialogDsChuyenDi(state, value) {
            state.dialogChiTietChuyenDi = false;
            state.dialogDsChuyenDi = value;
            if (!value) {
                return (state.dialogLocDiemDen = true);
            }
            //Không đóng popup chọn tỉnh khi bản đồ bị lỗi
            let m = this.getters["banDo/map"];
            if (!m) {
                return;
            }
            state.dialogLocDiemDen = false;
        },
        setDialogDsChonDiemDen(state, value) {
            state.dialogChiTietChuyenDi = false;
            state.dialogDsChuyenDi = false;
            state.dialogDsChonDiemDen = value;
            if (!value) {
                return (state.dialogLocDiemDen = true);
            }
            state.dialogLocDiemDen = false;
        },
        setDialogLocDiemDen(state, value) {
            state.dialogLocDiemDen = value;
        },
        setGuidNhaXe(state, value) {
            state.guidNhaXe = value;
        },
        setTimKiemTheoViTri(state, value) {
            state.timKiemTheoViTri = value;
        },
        setTempTimKiem(state, value) {
            state.tempTimKiem = value;
        },
        setTempLayCacChuyenDiDauTien(state) {
            state.tempLayCacChuyenDiDauTien++;
        },
        //model
        setSapXepDsChuyenDi(state, value) {
            state.model.sapXepDsChuyenDi = value;
        },
        setLocNgayDsChuyenDi(state, value) {
            state.model.locNgayDsChuyenDi = value;
        },
        setTimKiem(state, value) {
            state.model.timKiem = value;
        },
        setTinhDangChon(state, value) {
            state.model.tinhDangChon = value;
        },
        setHuyenDangChon(state, value) {
            state.model.huyenDangChon = value;
        },
        setXaDangChon(state, value) {
            state.model.xaDangChon = value;
        },
        //data
        setDsNamChuyenDiDauTien(state, value) {
            state.data.dsNamChuyenDiDauTien.push(value);
        },
        resetContext(context) {
            Object.assign(context, getDefaultState());
        },
    },
    actions: {
        layDanhSachLoTrinhSapXuatBen({ state, commit, dispatch }) {
            commit("setData", { key: "dsNamChuyenDiDauTien", value: [] });
            commit("setModel", { key: "diemDenDangChon", value: null });
            let check = state.model.sapXepDsChuyenDi.some((e) => e.order > 0);
            let query = {};
            if (!state.guidNhaXe) {
                query = {
                    sortOptions: check ? state.model.sapXepDsChuyenDi : [],
                    tuNgay: this.$Helper.Common.getStartDay(
                        state.model.locNgayDsChuyenDi
                    ),
                    denNgay: this.$Helper.Common.getEndDay(
                        state.model.locNgayDsChuyenDi
                    ),
                    idDiemDung: "00000000-0000-0000-0000-000000000000",
                };
            } else {
                //Lấy chuyến đi sắp xuất bến của nhà xe
                query = {
                    sortOptions: check ? state.model.sapXepDsChuyenDi : [],
                    guidNhaXe: state.guidNhaXe,
                    tuNgay: this.$Helper.Common.getStartDay(
                        state.model.locNgayDsChuyenDi
                    ),
                    denNgay: this.$Helper.Common.getEndDay(
                        state.model.locNgayDsChuyenDi
                    ),
                };
            }
            var load = this.$Helper.DevExDataSourceCustom(
                async (loadOptions) => {
                    try {
                        this.$loading.show();
                        let resp = await this.$Core.Api.Make(
                            !state.guidNhaXe
                                ? this.$i18n.global.t("url.LoTrinhSapXuatBen")
                                : this.$i18n.global.t(
                                      "url.TimKiemChuyenDiSapXuatBenCuaNhaXe"
                                  )
                        ).Post(
                            {
                                loadOptions: loadOptions,
                                args: query || {},
                            } || {}
                        );
                        this.$loading.hide();
                        if (resp.StatusCode === 200) {
                            let stt = 1;
                            let result = resp.Data.data.data || [];
                            result.forEach((e) => {
                                e.stt = stt++;
                                dispatch("layNamChuyenDiDauTien", e);
                            });
                            commit("setData", {
                                key: "dsTatCaChuyenDi",
                                value: result,
                            });
                            commit("setTempLayCacChuyenDiDauTien");
                            // if (resp.Data.errorCode) {
                            //     commit("setDialogDsChuyenDi", false)
                            // }
                            return result;
                        } else {
                            commit("setDialogDsChuyenDi", false);
                            return [];
                        }
                    } catch (ex) {
                        this.$loading.hide();
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        throw ex;
                    }
                }
            );
            commit("setData", { key: "dsChuyenDi", value: load.Store });
            commit("set", { key: "laChuyenDiSapXuatBen", value: true });
            // commit("setDialogDsChuyenDi", true)
            return load;
        },
        layDanhSachChuyenDi(
            { state, commit, dispatch, rootGetters },
            { maLoTrinh }
        ) {
            try {
                // Có maLoTrinh thì không xóa lộ trình trên bản đồ
                // và để kich vào lộ trình sẽ tìm kiếm chuyến đi theo lộ trình đó
                //Không có maLoTrinh thì sẽ lấy 5 lộ trình đầu tiên và vẽ lên bản đồ
                if (!maLoTrinh) {
                    commit("setData", {
                        key: "dsNamChuyenDiDauTien",
                        value: [],
                    });
                }
                let viTri = rootGetters["banDo/getLatlongtude"]; //Tìm kiếm chuyến đi trên bản đồ theo vị trí
                // if (!maLoTrinh && !state.model.diemDenDangChon) {
                //     throw new Error("Không có diemDenDangChon")
                // }
                let diemDenDangChon = state.model.diemDenDangChon;
                let check = state.model.sapXepDsChuyenDi.some(
                    (e) => e.order > 0
                );
                let query = {
                    sortOptions: check ? state.model.sapXepDsChuyenDi : [],
                    tuNgay: this.$Helper.Common.getStartDay(
                        state.model.locNgayDsChuyenDi
                    ),
                    denNgay: this.$Helper.Common.getEndDay(
                        state.model.locNgayDsChuyenDi
                    ),
                    maDiemDi: this.$Core.AuthApi.TokenParsed.PlacemarkId
                        ? this.$Core.AuthApi.TokenParsed.PlacemarkId
                        : diemDenDangChon
                        ? diemDenDangChon.placemarkId
                        : null,
                    maDiemDen: diemDenDangChon
                        ? diemDenDangChon.placemarkId
                        : null,
                    loaiDiemDen: diemDenDangChon
                        ? diemDenDangChon.loaiViTri
                        : null,
                    thongTinViTri: {
                        longitude:
                            Array.isArray(viTri) && viTri.length > 0
                                ? viTri[0]
                                : 0,
                        latitude:
                            Array.isArray(viTri) && viTri.length > 0
                                ? viTri[1]
                                : 0,
                    },
                };
                if (maLoTrinh) {
                    query.maLuongTuyen = maLoTrinh;
                }
                var load = this.$Helper.DevExDataSourceCustom(
                    async (loadOptions) => {
                        try {
                            let resp = await this.$Core.Api.Make(
                                maLoTrinh
                                    ? this.$i18n.global.t(
                                          "url.TimKiemChuyenDiTheoMaLT"
                                      )
                                    : this.$i18n.global.t(
                                          "url.DanhSachChuyenDiDuocUyThac"
                                      )
                            ).Post(
                                {
                                    loadOptions: loadOptions,
                                    args: query || {},
                                } || {}
                            );
                            this.$loading.hide();
                            if (resp.StatusCode === 200) {
                                let stt = 1;
                                let result = resp.Data.data.data || [];
                                // if (!maLoTrinh) {
                                result.forEach((e) => {
                                    e.stt = stt++;
                                    if (!maLoTrinh) {
                                        dispatch("layNamChuyenDiDauTien", e);
                                    }
                                });
                                commit("setTempLayCacChuyenDiDauTien");
                                commit("setData", {
                                    key: "dsTatCaChuyenDi",
                                    value: result,
                                });
                                // }
                                return result;
                            } else {
                                return [];
                            }
                        } catch (ex) {
                            this.$loading.hide();
                            if (ex.message == "Network Error")
                                throw Error("Không kết nối được đến máy chủ!");
                            throw ex;
                        }
                    }
                );
                commit("setData", { key: "dsChuyenDi", value: load.Store });
                commit("set", { key: "laChuyenDiSapXuatBen", value: false });
                commit("setDialogDsChuyenDi", true);
                return load;
            } catch (error) {
                console.log("🚀 ~ ", error);
            }
        },
        layNamChuyenDiDauTien({ state, commit }, chuyendi) {
            // 5 chuyến đi đầu tiên để vẽ lên bản đồ số
            if (state.data.dsNamChuyenDiDauTien.length > 4) {
                return;
            }
            let check = state.data.dsNamChuyenDiDauTien.some(
                (s) =>
                    s.loTrinh.googleMapObject ==
                    chuyendi.loTrinh.googleMapObject
            );
            if (chuyendi.loTrinh.googleMapObject && !check) {
                commit("setDsNamChuyenDiDauTien", chuyendi);
            }
        },
        async layDanhSachDiemDen({ state, commit }) {
            try {
                this.$loading.show();
                let loadOption = new DxLoadOptions();
                let timKiem = state.model.diemDenDangChon
                    ? state.model.diemDenDangChon.tenViTri
                    : state.model.timKiem;
                loadOption.addFilter("tenViTriChoTimKiem", "contains", timKiem);
                loadOption.addFilter("or");
                loadOption.addFilter("diaChiTimKiem", "contains", timKiem);
                loadOption.setTake(10);
                let rs = await this.$Core.Api.Make(
                    this.$i18n.global.t("url.LayDanhSachDiemDen")
                ).Post({
                    args: {},
                    loadOptions: JSON.stringify(loadOption),
                });
                this.$loading.hide();
                if (rs.StatusCode === 200) {
                    let data = rs.Data.data.data;
                    commit("setData", { key: "dsDiemDen", value: data });
                    return rs.Data.data.data;
                }
            } catch (error) {
                this.$loading.hide();
                commit("set", { key: "data.dsDiemDen", value: [] });
                throw new Error(error);
            }
        },
        async layDsChuyenDiTheoLoai({ state, dispatch }) {
            //Chỉ để lấy ds chuyến đi theo lộ trình sắp xuất bến hay chuyến đi theo ngày
            try {
                if (state.laChuyenDiSapXuatBen) {
                    await dispatch("layDanhSachLoTrinhSapXuatBen");
                } else {
                    await dispatch("layDanhSachChuyenDi", {});
                }
            } catch (error) {
                throw new Error("Đã xảy ra lỗi!");
            }
        },
        async layDsTinh({ commit }, take) {
            //Chỉ để lấy ds chuyến đi theo lộ trình sắp xuất bến hay chuyến đi theo ngày
            try {
                this.$loading.show();
                let loadOption = new DxLoadOptions();
                loadOption.setTake(take || 17);
                let rs = await this.$Core.Api.Map(
                    this.$i18n.global.t("url.LayDanhSachTinh")
                ).Post(loadOption);
                this.$loading.hide();
                if (rs.StatusCode === 200) {
                    let data = rs.Data.data.data;
                    let stt = 1;
                    data.forEach((e) => {
                        e.active = false;
                        e.id = stt++;
                    });
                    data.push({ id: stt, active: false, TenViTri: "Xem thêm" });
                    commit("setData", { key: "dsTinh", value: data });
                    return data;
                }
            } catch (error) {
                this.$loading.hide();
                throw new Error(error);
            }
        },
        async layDsHuyen({ state, commit }) {
            //Chỉ để lấy ds chuyến đi theo lộ trình sắp xuất bến hay chuyến đi theo ngày
            try {
                if (
                    !state.model.tinhDangChon ||
                    !state.model.tinhDangChon.IdTinh
                ) {
                    throw new Error("Không có idTinh!");
                }
                this.$loading.show();
                let loadOption = new DxLoadOptions();
                loadOption.setTake(100);
                loadOption.addFilter(
                    "GID1",
                    "=",
                    state.model.tinhDangChon.IdTinh
                );
                let rs = await this.$Core.Api.Map(
                    this.$i18n.global.t("url.LayDanhSachHuyen")
                ).Post(loadOption);
                this.$loading.hide();
                if (rs.StatusCode === 200) {
                    let data = rs.Data.data.data;
                    let stt = 1;
                    data.forEach((e) => {
                        e.active = false;
                        e.id = stt++;
                    });
                    commit("setData", { key: "dsHuyen", value: data });
                    return data;
                }
            } catch (error) {
                throw new Error(error);
            }
        },
        async layDsXa({ state, commit }) {
            //Chỉ để lấy ds chuyến đi theo lộ trình sắp xuất bến hay chuyến đi theo ngày
            try {
                if (
                    !state.model.huyenDangChon ||
                    !state.model.huyenDangChon.IdHuyen
                ) {
                    throw new Error("Không có idHuyen!");
                }
                this.$loading.show();
                let loadOption = new DxLoadOptions();
                loadOption.setTake(100);
                loadOption.addFilter(
                    "GID2",
                    "=",
                    state.model.huyenDangChon.IdHuyen
                );
                let rs = await this.$Core.Api.Map(
                    this.$i18n.global.t("url.LayDanhSachXa")
                ).Post(loadOption);
                this.$loading.hide();
                if (rs.StatusCode === 200) {
                    let data = rs.Data.data.data;
                    let stt = 1;
                    data.forEach((e) => {
                        e.active = false;
                        e.id = stt++;
                    });
                    commit("setData", { key: "dsXa", value: data });
                    return data;
                }
            } catch (error) {
                throw new Error(error);
            }
        },
        async kiemTraTrangThaiChuyenDi({}, { idXe, soKhach }) {
            //Kiểm tra trạng thái chuyến đi xem có được mua vé hay không
            //soKhach là số chỗ mua ghế phụ, mặc định 0
            try {
                if (!idXe) {
                    throw new Error("Không có idXe");
                }
                this.$loading.show();
                let rs = await this.$Core.Api.Make(
                    this.$i18n.global.t("url.KiemTraTrangThaiChuyenDi"),
                    { maChuyenDi: idXe, soKhach: soKhach || 0 }
                ).Get();
                this.$loading.hide();
                if (rs.StatusCode === 200) {
                    let data = { data: rs.Data.data, message: rs.Data.message };
                    return data;
                }
            } catch (error) {
                throw new Error(error);
            }
        },
        xoaLocTheoDiaDiem({ state, commit }) {
            commit("setTinhDangChon", null);
            commit("setHuyenDangChon", null);
            commit("setXaDangChon", null);
            state.data.dsTinh.forEach((e) => {
                e.active = false;
            });
        },
    },
};
