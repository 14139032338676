const getDefaultState = () => {
    return {
        maGiaoDich: "",
        data: {
            danhSachViDienTu: [],
            danhSachDiemDung: [],
            danhSachThongDiep: [],
            goiYDanhSachDiemDung: [],
            goiYDanhSachSoChoPhu: []
        },
        model: {
            viDienTuDangChon: null,
            thongTinThanhToan: null,
            soDienThoai: "",
            giaVeTheoDiemXuong: 0,
            soChoMuaGhePhu: 1,
            diemXuongDangChon: null,
            thongTinTaiKhoanThanhToan: {
                nguoiNhan: "",
                taiKhoan: "",
                soDienThoai: "",
                tenNganHang: "",
            }
        }
    }
}
export default {
    namespaced: true,
    state: getDefaultState,
    getters: {
        getDanhSachViDienTu: state => {
            return state.data.danhSachViDienTu.map(e => {
                e.active = false;
                return e;
            });
        },
        //model
        getViDienTuDangChon: state => state.model.viDienTuDangChon,
        getThongTinThanhToan: state => state.model.thongTinThanhToan,
        getSoDienThoai: state => state.model.soDienThoai,
        getThongTinTaiKhoanThanhToan: state => state.model.thongTinTaiKhoanThanhToan,
        getDiemXuongDangChon: state => state.model.diemXuongDangChon,
        getGiaVeTheoDiemXuong: state => state.model.giaVeTheoDiemXuong,
        getSoChoMuaGhePhu: state => state.model.soChoMuaGhePhu,
        //data
        getDanhSachDiemDung: state => state.data.danhSachDiemDung,
        getGoiYDanhSachDiemDung: state => state.data.goiYDanhSachDiemDung,
        getDanhSachThongDiep: state => state.data.danhSachThongDiep,
        getDanhSachSoChoPhu: state => state.data.goiYDanhSachSoChoPhu,
    },
    mutations: {
        set(state, { key, value }) {
            state[key] = value;
        },
        setModel(state, { key, value }) {
            state["model"][key] = value;
        },
        setData(state, { key, value }) {
            state["data"][key] = value;
        },
        setSoDienThoai(state, value) {
            state.model.soDienThoai = value
        },
        setSoChoMuaGhePhu(state, value) {
            state.model.soChoMuaGhePhu = value
        },
        setGiaVeTheoDiemXuong(state, value) {
            state.model.giaVeTheoDiemXuong = value
        },
        setGoiYDanhSachSoChoPhu(state, soCho) {
            state.data.goiYDanhSachSoChoPhu = []
            let id = 1;
            for (let i = 1; i <= soCho; i++) {
                state.data.goiYDanhSachSoChoPhu.push(
                    {
                        id: id++,
                        text: i + "",
                        active: false
                    }
                )

            }
        },
        resetContext(context) {
            Object.assign(context, getDefaultState());
        }
    },
    actions: {
        async layDanhSachViDienTu({ commit }, idDoanhNghiep) {
            try {
                if (!idDoanhNghiep) {
                    throw new Error("Không có idDoanhNghiep")
                }
                this.$loading.show();
                let rs = await this.$Core.Api.Make(
                    this.$i18n.global.t("url.DanhSachViDienTu"), {
                    idDoanhNghiep: idDoanhNghiep
                }
                ).Get();
                this.$loading.hide();
                if (rs.StatusCode === 200) {
                    let data = rs.Data.data;
                    commit("setData", { key: "danhSachViDienTu", value: data })
                    return rs.Data.data;
                }
            } catch (error) {
                this.$loading.hide();
                throw new Error(error)
            }
        },
        async layDanhSachDiemDung({ commit }, { guidLoTrinh }) {
            try {
                if (!guidLoTrinh) {
                    throw new Error("Không có guidLoTrinh")
                }
                this.$loading.show();
                let rs = await this.$Core.Api.Make(
                    this.$i18n.global.t("url.DanhSachDiemDungTheoChuyenDi"), {
                        guidLoTrinh: guidLoTrinh
                }
                ).Get();
                this.$loading.hide();
                if (rs.StatusCode === 200) {
                    let data = rs.Data.data;
                    commit("setData", { key: "danhSachDiemDung", value: data })
                    return rs.Data.data;
                }
            } catch (error) {
                this.$loading.hide();
                throw new Error(error)
            }
        },
        async layGoiYDanhSachDiemXuong({ commit, state }, { guidChuyenDi }) {
            try {
                if (!guidChuyenDi) {
                    throw new Error("Không có guidChuyenDi")
                }
                if (!state.model.soDienThoai) {
                    throw new Error("Không có soDienThoai")
                }
                let rs = await this.$Core.Api.Make(
                    this.$i18n.global.t("url.GoiYDanhSachDiemXuong"), {
                    guidChuyenDi: guidChuyenDi,
                    soDienThoai: state.model.soDienThoai,
                }
                ).Get();
                if (rs.StatusCode === 200) {
                    let rsData = rs.Data.data;
                    let data = rsData.map(e => {
                        return {
                            id: e.guidDiemDung,
                            text: e.tenDiemDung,
                            active: false
                        }
                    });
                    commit("setData", { key: "goiYDanhSachDiemDung", value: data })
                    return rs.Data.data;
                }
            } catch (error) {
                this.$loading.hide();
                throw new Error(error)
            }
        },
        async layThongTinTaiKhoanThanhToan({ commit }) {
            try {
                let rs = await this.$Core.Api.Make(
                    this.$i18n.global.t("url.LayThongTinTaiKhoanThanhToan")).Get();
                if (rs.StatusCode === 200) {
                    let data = rs.Data.data;
                    commit("setModel", { key: "thongTinTaiKhoanThanhToan", value: data })
                    return rs.Data.data;
                }
            } catch (error) {
                this.$loading.hide();
                throw new Error(error)
            }
        },
        async layDanhSachThongDiep({ commit, state }) {
            try {
                this.$loading.show();
                let rs = await this.$Core.Api.Make(
                    this.$i18n.global.t("url.LayDanhSachThongDiep")
                ).Get();
                this.$loading.hide();
                if (rs.StatusCode === 200) {
                    let rsData = rs.Data.data;
                    let data = rsData.map(e => {
                        return {
                            id: e.guidThongDiep,
                            text: e.noiDung,
                            active: false
                        }
                    });
                    commit("setData", { key: "danhSachThongDiep", value: data })
                    return rs.Data.data;
                }
            } catch (error) {
                this.$loading.hide();
                throw new Error(error)
            }
        },
        async layGiaTienGoiYTheoDiemXuong({ commit, state }, { guidChuyenDi }) {
            try {
                this.$loading.show();
                let rs = await this.$Core.Api.Make(
                    this.$i18n.global.t("url.LayGiaTienGoiYTheoDiemXuong"),
                    {
                        GuidChuyenDi: guidChuyenDi,
                        GuidChoNgoi: "00000000-0000-0000-0000-000000000000",
                        GuidDiemXuong: state.model.diemXuongDangChon ? state.model.diemXuongDangChon.guidDiemXuong : "",
                    }
                ).Get();
                this.$loading.hide();
                if (rs.StatusCode === 200) {
                    let data = rs.Data.data;
                    commit("setModel", { key: "giaVeTheoDiemXuong", value: data || 0 })
                    return data;
                }
            } catch (error) {
                commit("setModel", { key: "giaVeTheoDiemXuong", value: null })
                this.$loading.hide();
                throw new Error(error)
            }
        },
        async thanhToan({ state, commit }, { url = "ThanhToan", maChuyenDi = "", guidDoanhNghiep = "", danhSachMaSoGhe = [], idPhien = null }) {
            try {
                if (!state.model.viDienTuDangChon) {
                    throw new Error("Không có viDienTuDangChon")
                }
                let query = {
                    idVi: state.model.viDienTuDangChon.id,
                    maChuyenDi: maChuyenDi,
                    danhSachMaSoGhe: danhSachMaSoGhe,
                    soDienThoai: state.model.thongTinThanhToan.soDienThoai,
                    hoTen: "",
                    email: "",
                    ghiChu: state.model.thongTinThanhToan.ghiChu,
                    guidDoanhNghiep: guidDoanhNghiep,
                    idPhien: idPhien,
                    maDiemXuong: state.model.diemXuongDangChon ? state.model.diemXuongDangChon.guidDiemXuong : "",
                    tenDiemXuong: state.model.diemXuongDangChon ? state.model.diemXuongDangChon.tenDiemXuong : "",
                }
                this.$loading.show();
                let rs = await this.$Core.Api.Make(
                    this.$i18n.global.t(`url.${url}`)
                ).Post(query);
                this.$loading.hide();
                if (rs.StatusCode === 200) {
                    let data = rs.Data.data;
                    commit("set", { key: "maGiaoDich", value: data.maGiaoDich })
                }
                return rs
            } catch (error) {
                this.$loading.hide();
                throw new Error(error)
            }
        },
        async huyThanhToan({ state }) {
            try {
                if (!state.maGiaoDich) {
                    throw new Error("Không có maGiaoDich")
                }
                this.$loading.show();
                let rs = await this.$Core.Api.Make(
                    this.$i18n.global.t("url.HuyThanhToan"), {
                    maGiaoDich: state.maGiaoDich
                }
                ).Get();
                this.$loading.hide();
                return rs
            } catch (error) {
                this.$loading.hide();
                throw new Error(error)
            }
        },
        async kiemTraThanhToan({ state }) {
            try {
                if (!state.maGiaoDich) {
                    throw new Error("Không có maGiaoDich")
                }
                let rs = await this.$Core.Api.Make(
                    this.$i18n.global.t("url.KiemTraThanhToan"), {
                    maGiaoDich: state.maGiaoDich
                }
                ).Get();
                return rs
            } catch (error) {
                throw new Error(error)
            }
        },
        async ketQuaGiaoDich({ state }) {
            try {
                if (!state.maGiaoDich) {
                    throw new Error("Không có maGiaoDich")
                }
                let rs = await this.$Core.Api.Make(
                    this.$i18n.global.t("url.KetQuaGiaoDich"), {
                    maGiaoDich: state.maGiaoDich
                }
                ).Get();
                return rs
            } catch (error) {
                throw new Error(error)
            }
        }
    }
}