
export default {
    namespaced: true,
    state: () => ({
        idleInterval: null,
        idleTimeReset: 0,
        idleTimeout: 60, // 1 phút không tương tác với màn hình thì 
        idleBackToLink: "/", //về trang chủ
    }),
    getters: {
        // idleTime: state => state.idleTime,
        // idleTimeout: state => state.idleTimeout,
        idleBackToLink: state => state.idleBackToLink,
        // idleScreen: state => state.idleScreen,
    },
    mutations: {
        setIdleTimeout(state, value) {
            state.idleTimeout = value;
        },
    },
    actions: {
        listener({ state }) {
            window.removeEventListener("mousemove", () => { })
            window.removeEventListener("keypress", () => { })
            window.removeEventListener("touchstart", () => { })
            window.removeEventListener("touchmove", () => { })
            window.addEventListener("mousemove", function () {
                state.idleTimeReset = 0;
            })
            window.addEventListener("keypress", function () {
                state.idleTimeReset = 0;
            })
            window.addEventListener("touchstart", function () {
                state.idleTimeReset = 0;
            })
            window.addEventListener("touchmove", function () {
                state.idleTimeReset = 0;
            })
        },
        setIdleTimer({ state }) {
            function idleTimed() {
                state.idleTimeReset++;
                if (state.idleTimeReset < state.idleTimeout) {
                    return;
                } else if (window.location.pathname == state.idleBackToLink) {
                    return;
                }
                window.location.replace(state.idleBackToLink)
            }
            clearInterval(state.idleInterval);
            state.idleTimeReset = 0;
            state.idleInterval =
                setInterval(idleTimed, 1000);
        },
        startIdleTimer({ state, dispatch }, idleTimeout) {
            dispatch("stopIdleTimer")
            if (idleTimeout) {
                state.idleTimeout = idleTimeout / 1000;
            }
            dispatch('listener');
            dispatch('setIdleTimer');
        },
        stopIdleTimer({ state }) {
            state.idleTimeReset = 0;
            clearInterval(state.idleInterval);
        }
    }
}