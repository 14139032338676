export default {
    namespaced: true,
    state: () => ({
        thongTinCauHinhChung: null,
        thongTinBenXe: null,
        dialogDangPhatTrien: false
    }),
    getters: {
        getCauHinhChung: state => {
            let cauHinhChung = JSON.parse(localStorage.getItem("ThongTinCauHinhChung"))
            if (cauHinhChung) {
                return cauHinhChung
            }
            return {
                soChoToiDa: 3,
                soLuongKetQua: 20,
                soNgayTimKiem: 7,
                thoiGianQuayLaiTrangChu: 120000,
                thoiGianQuetMaQR: 120000,
            }
        },
        getThongTinBenXe: (state) => {
            let benXe = JSON.parse(localStorage.getItem("ThongTinBenXe"))
            if (benXe) {
                return benXe
            }
            return {
                tenBenXe: "",
                id: "",
                viTri: {
                    tenDiemDung: "",
                    toaDo: {
                        latitude: "",
                        longitude: ""
                    },
                    id: ""
                }
            };
        },
        getDialogDangPhatTrien: state => state.dialogDangPhatTrien
    },
    mutations: {
        setCauHinhChung(state, value) {
            state.thongTinCauHinhChung = value;
        },
        setDialogDangPhatTrien(state, value) {
            state.dialogDangPhatTrien = value;
        }
    },
    actions: {
        async layCauHinhChung({ }) {
            try {
                this.$loading.show();
                let rs = await this.$Core.Api.Make(
                    this.$i18n.global.t("url.CauHinhChung")
                ).Get();
                this.$loading.hide();
                if (rs.StatusCode === 200) {
                    let data = rs.Data.data;
                    // data.thoiGianQuayLaiTrangChu = 6000000;
                    localStorage.setItem(
                        "ThongTinCauHinhChung",
                        JSON.stringify(data)
                    );
                    return rs.Data.data;
                }
            } catch (error) {
                this.$loading.hide();
                throw new Error(error)
            }
        },
        async layThongTinBenXe({ }) {
            try {

                this.$loading.show();
                let rs = await this.$Core.Api.Make(
                    this.$i18n.global.t("url.ThongTinBenXe")
                ).Get();
                this.$loading.hide();
                if (rs.StatusCode === 200) {
                    let data = rs.Data.data;
                    localStorage.setItem(
                        "ThongTinBenXe",
                        JSON.stringify(data)
                    );
                    return rs.Data.data;
                }
            } catch (error) {
                this.$loading.hide();
                throw new Error(error)
            }
        },
        async layThongTinQuangCao({ }) {
            try {

                this.$loading.show();
                let rs = await this.$Core.Api.Make(
                    this.$i18n.global.t("url.ThongTinQuangCao")
                ).Get();
                this.$loading.hide();
                if (rs.StatusCode === 200) {
                    let data = rs.Data.data;
                    return data;
                }
            } catch (error) {
                this.$loading.hide();
                throw new Error(error)
            }
        },
        async layDsNhaXeSuDungQuangCao({ }) {
            try {

                this.$loading.show();
                let rs = await this.$Core.Api.Make(
                    this.$i18n.global.t("url.ThongTinNhaXeSuDungQuangCao")
                ).Get();
                this.$loading.hide();
                if (rs.StatusCode === 200) {
                    let data = rs.Data.data;
                    return data;
                }
            } catch (error) {
                this.$loading.hide();
                throw new Error(error)
            }
        },
        async layThongTinChucNang({ }) {
            try {

                this.$loading.show();
                let rs = await this.$Core.Api.Make(
                    this.$i18n.global.t("url.ThongTinChucNang")
                ).Get();
                this.$loading.hide();
                if (rs.StatusCode === 200) {
                    let data = rs.Data.data;
                    return data;
                }
            } catch (error) {
                this.$loading.hide();
                throw new Error(error)
            }
        },
    }
}